import React, {useState, useEffect} from 'react';
import './style.scss';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import Purchase  from '../Purchase';
import { formatPrice } from '../../services/util';
import firebase from '../Firebase/firebase';

const Checkout = (props) => {
  const [value, onChange] = useState(new Date(Date.now() + 8.64e7));
  const [time, onChangeTime] = useState(null);
  const [maxDate, changeMaxDate] = useState(new Date(Date.now() + 12096e5))
  const [minDate, changeMinDate] = useState(new Date(Date.now() + 8.64e7))
  const tileDisable = ({ date, view }) => {
    // Disable tiles in month view only
    if (view === 'month') {
      if(date.getDay() == 5)
        return 1;
      else
        return 0;
    }    
  }
  const validateSelection = () => {
    if(value && time)
    {
      console.log(value);
      console.log(time);
      var json = {};
      json['date'] = value.toISOString().substring(0, 10);;
      json['time'] = time;
      firebase.firestore().collection('pickup-time').doc(props.code).set(json);
    }
    else
      alert('Please select a date and a time for pick up');
  }
  const {
    price,
    totalprice,
    code,
    show,
    closeCheckout
  } = props
  if(!show)
  {
    console.log(props.show)
    return null;
  }
  return (
    <div id="popup1" className="overlay">
      <div className="popup">
        <h2>Checkout</h2>
        <a className="close" onClick={props.closeCheckout}>&times;</a>
        <div className="content">
          <Calendar
            onChange={onChange}
            value={value}
            defaultValue={value}
            maxDate={maxDate}
            minDate={minDate}
            minDetail="month"
            tileDisabled={tileDisable}
          />
          <RadioGroup onChange={ onChangeTime }>
            <RadioButton value="5:00PM" rootColor="#c2c2c2" pointColor="#006edc">
              5:00PM
            </RadioButton>
            <RadioButton value="5:30PM" rootColor="#c2c2c2" pointColor="#006edc">
              5:30PM
            </RadioButton>
            <RadioButton value="6:00PM" rootColor="#c2c2c2" pointColor="#006edc">
              6:00PM
            </RadioButton>
            <RadioButton value="6:30PM" rootColor="#c2c2c2" pointColor="#006edc">
              6:30PM
            </RadioButton>
            <RadioButton value="7:00PM" rootColor="#c2c2c2" pointColor="#006edc">
              7:00PM
            </RadioButton>
            <RadioButton value="7:30PM" rootColor="#c2c2c2" pointColor="#006edc">
              7:30PM
            </RadioButton>
            <RadioButton value="8:00PM" rootColor="#c2c2c2" pointColor="#006edc">
              8:00PM
            </RadioButton>
          </RadioGroup>
          <div onClick={validateSelection} className="buy-btn">
            <Purchase price={formatPrice(props.totalprice + (props.totalprice * 0.0925))} totalprice={props.totalprice} code={props.code} date={value} time={time} closeCheckout={closeCheckout} />
          </div>
          
        </div>
      </div>
    </div>
  )
}
export default Checkout;