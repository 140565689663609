import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Notfound from '../Notfound';
import RouteLoad from './RouteLoad'

const Routing = () => (
    <Switch>
        <Route exact path='/' component={Notfound}/>
        <Route path='/:code' component={RouteLoad}/>
    </Switch>   
)

export default Routing