import React from 'react'
import Filter from '../../Shelf/Filter'
import Shelf from '../../Shelf'
import FloatCart from '../../FloatCart'

const RouteLoad = (props) => {
  return (
    <React.Fragment>
        <main>
        <Filter />
        <Shelf {...props}/>
        </main>
        <FloatCart {...props}/>
    </React.Fragment>
  )
}

export default RouteLoad
