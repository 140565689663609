import firebase from 'firebase/app';
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyBZ0ZXbOfbJGWEuzw0aAw6hkx7nwVjJ_a8",
    authDomain: "aigrill.firebaseapp.com",
    databaseURL: "https://aigrill.firebaseio.com",
    projectId: "aigrill",
    storageBucket: "aigrill.appspot.com",
    messagingSenderId: "381406207975",
    appId: "1:381406207975:web:baf18b0279521826eaa742"
  };


firebase.initializeApp(firebaseConfig);

export default firebase;