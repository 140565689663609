import 'babel-polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import App from './components/App';
import Root from './Root';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.scss';

const stripePromise = loadStripe("pk_live_51I3EvIDdjKeTyUTMjhILZOgei5BaPpC6iSIVjh0qud9hcoLrw4uX8rchXFQwaSHvaWt8KXeXeFh7G6sKXCZBsV2000y2kVra3w");

ReactDOM.render(
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <Root>
            <App />
        </Root>
      </Elements>
    </BrowserRouter>,
  document.getElementById('root')
);
