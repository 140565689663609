import React from 'react';
import { v4 as uuidv4 } from 'uuid';


const Notfound = (props) => {
    var id = uuidv4();
    console.log(id);
    props.history.push('/'+id );
    return (
        <h1>Loading...</h1>
    )
  }

export default Notfound;