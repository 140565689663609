import { Redirect } from "react-router-dom"; 
import StripeCheckout from 'react-stripe-checkout';
import React, {useState, useEffect} from 'react';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';

const onToken = (token, props) => {
  const {
    code,
    price,
    date,
    time,
    closeCheckout
  } = props;
  token['cartcode'] = code;
  token['amount'] = price * 100;
  token['date'] = date;
  token['time'] = time
  fetch('/api/complete-purchase', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(token),
  }).then(response => {
    if (response.status === 200) {
      alert("Successfully Paid! Your order id is: " + code);
      props.closeCheckout()
    } else
        alert("payment failed! Please try again later or contact support.");
  });

};

const Purchase = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const {
    price,
    code,
    date,
    time
  } = props
  let secret = ""
  let d;
  let t;
  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Ai Grill',
          amount: Math.round(price * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pr.canMakePayment().then(result => {
        if (result) {
          pr.on('paymentmethod', e =>handlePaymentMethod(e, props));
          setCanMakePayment(true);
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  const handlePaymentMethod = async (event) => {
    console.log('fetch payment intent')
    const response = await fetch("/api/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({id: code, date: props.date, time: props.time})
    });
    const body = await response.json()
    secret = body.clientSecret
    console.log("handling payment with client secret: " + secret)
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      secret,
      {
        payment_method: event.paymentMethod.id
      }
    );
    if (error) {
      console.log(error);
      event.complete('fail')
      return;
    }
    if (paymentIntent.status === 'succeeded') {
      event.complete('success')
      alert("Successfully Paid! Your order id is: " + code);
    } else {
      console.warn(
        `Unexpected status: ${paymentIntent.status} for ${paymentIntent}`
      );
    }
  }

  if(date === null || time === null)
  {
    return (<span>Please Select a Date and a Time</span>)
  }

  if (canMakePayment) {
    return <PaymentRequestButtonElement options={{paymentRequest}} />
  }

  return (
    <StripeCheckout
      name="Ai Grill"
      description="Check Out"
      token={token => onToken(token, props)}
      amount={Math.round(price * 100)}
      stripeKey={'pk_live_51I3EvIDdjKeTyUTMjhILZOgei5BaPpC6iSIVjh0qud9hcoLrw4uX8rchXFQwaSHvaWt8KXeXeFh7G6sKXCZBsV2000y2kVra3w'}
      zipCode
    >
      {<span {...props}>PURCHASE</span>}
    </StripeCheckout>
  )
}

/**
class Purchase extends React.Component {
  static propTypes = {
    price: PropTypes.string.isRequired,
    totalprice: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired
  };
  state = { redirect: null };

  onToken = (token) => {
    const {
      price,
      totalprice,
      code
    } = this.props;
    token['cartcode'] = code;
    fetch('/api/complete-purchase', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(token),
    }).then(response => {
      if (response.status == 200) {
        alert("Successfully Paid! Your order id is: " + code);
        this.setState({ redirect: "/" });
      } else
        alert("payment failed! Please try again later or contact support.");

    });

  };

  render() {
    const { price } = this.props;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <StripeCheckout
        name="Tasty Place"
        description="Check Out"
        token={this.onToken}
        amount={price * 100}
        stripeKey={'pk_test_51I3EvIDdjKeTyUTMp89jvWn7llWnhbEE6kwSAYgf55SIVDvKG9YZ4C5NQ2zNun47gbbhtYlxYqkTlXUpXXPaa8tG00xFHeZaeo'}
        zipCode
      >
        {<span {...this.props}>PURCHASE</span>}
      </StripeCheckout>
    )
  }
}
 */
export default Purchase;