import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadCart, removeProduct } from '../../services/cart/actions';
import { updateCart } from '../../services/total/actions';
import CartProduct from './CartProduct';
import Purchase  from '../Purchase';
import Checkout  from '../Checkout';

import { formatPrice } from '../../services/util';
import './style.scss';

import firebase from '../Firebase/firebase';

class FloatCart extends Component {
  static propTypes = {
    loadCart: PropTypes.func.isRequired,
    updateCart: PropTypes.func.isRequired,
    cartProducts: PropTypes.array.isRequired,
    newProduct: PropTypes.object,
    removeProduct: PropTypes.func,
    productToRemove: PropTypes.object
  };

  state = {
    isOpen: false,
    last_update_timestamp: 0,
    showCheckout: false
  };

  componentDidMount() {
    const {cartProducts,updateCart} = this.props;
    firebase
    .firestore()
    .collection('shopping-cart')
    .doc('cart')
    .collection(this.props.match.params.code)
    .onSnapshot((snapshot) =>{
      cartProducts.length = 0;
      snapshot.docs.map((doc) => cartProducts.push(({
        uuid: doc.id,
        ...doc.data()
      })))
      updateCart(cartProducts);
      this.setState({last_update_timestamp: new Date().getTime()})
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newProduct !== this.props.newProduct) {
      this.addProduct(nextProps.newProduct);
    }

    if (nextProps.productToRemove !== this.props.productToRemove) {
      this.removeProduct(nextProps.productToRemove);
    }
  }

  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
  };

  addProduct = product => {
    const { cartProducts, updateCart } = this.props;
    let productAlreadyInCart = false;

    cartProducts.forEach(cp => {
      if (cp.id === product.id) {
        cp.quantity += product.quantity;
        productAlreadyInCart = true;
        firebase.firestore().collection('shopping-cart')
        .doc('cart')
        .collection(this.props.match.params.code).doc(cp.uuid).set(cp);
      }
    });

    if (!productAlreadyInCart) {
      firebase.firestore().collection('shopping-cart')
      .doc('cart').collection(this.props.match.params.code).add(product);
    }

    updateCart(cartProducts);
  };

  removeProduct = product => {
    firebase.firestore().collection('shopping-cart')
    .doc('cart').collection(this.props.match.params.code).doc(product.uuid).delete();
  };

  proceedToCheckout = () => {
    const {
      productQuantity
    } = this.props.cartTotal;
    this.setState({ showCheckout: true });
    if (!productQuantity) {
      alert('Add some yummy food into the cart!');
    } 
  };

  closeCheckOut = () => {
    this.setState({ showCheckout: false });
  }

  render() {
    const { cartTotal, cartProducts, removeProduct } = this.props;
    const products = cartProducts.map(p => {
      return (
        <CartProduct product={p} removeProduct={removeProduct} key={p.id} />
      );
    });

    let classes = ['float-cart'];

    if (!!this.state.isOpen) {
      classes.push('float-cart--open');
    }

    return (
      <div className={classes.join(' ')}>
        {/* If cart open, show close (x) button */}
        {this.state.isOpen && (
          <div
            onClick={() => this.closeFloatCart()}
            className="float-cart__close-btn"
          >
            X
          </div>
        )}

        {/* If cart is closed, show bag with quantity of product and open cart action */}
        {!this.state.isOpen && (
          <span
            onClick={() => this.openFloatCart()}
            className="bag bag--float-cart-closed"
          >
            <span className="bag__quantity">{cartTotal.productQuantity}</span>
          </span>
        )}

        <div className="float-cart__content">
          <div className="float-cart__header">
            <span className="bag">
              <span className="bag__quantity">{cartTotal.productQuantity}</span>
            </span>
            <span className="header-title">Cart</span>
          </div>

          <div className="float-cart__shelf-container">
            {products}
            {!products.length && (
              <p className="shelf-empty">
                Add some yummy food into the cart <br />
                :)
              </p>
            )}
          </div>

          <div className="float-cart__footer">
            <div className="sub">SUBTOTAL</div>
            <div className="sub-price">
              <p className="price__installment-price__val">
                {`${cartTotal.currencyFormat} ${formatPrice(
                  cartTotal.totalPrice,
                  cartTotal.currencyId
                )}`}
              </p>
            </div>
            <div className="sub">Tax 9.25%</div>
            <div className="sub-price">
              <p className="price__installment-price__val">
                {`${cartTotal.currencyFormat} ${formatPrice(
                  cartTotal.totalPrice * 0.0925,
                  cartTotal.currencyId
                )}`}
              </p>
            </div>
            <div className="tot">TOTAL</div>
            <div className="sub-price">
              <p className="sub-price__val">
                {`${cartTotal.currencyFormat} ${formatPrice(
                  cartTotal.totalPrice + (cartTotal.totalPrice * 0.0925),
                  cartTotal.currencyId
                )}`}
              </p>
            </div>
            <div>     
              <div onClick={() => this.proceedToCheckout()} className="buy-btn">
                <span>Checkout</span>
              </div> 
            </div>
          </div>
        </div>
        <Checkout price={formatPrice(cartTotal.totalPrice + (cartTotal.totalPrice * 0.0925))} totalprice={cartTotal.totalPrice} code={this.props.match.params.code} show={this.state.showCheckout} closeCheckout={this.closeCheckOut}/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  cartTotal: state.total.data
});

export default connect(
  mapStateToProps,
  { loadCart, updateCart, removeProduct }
)(FloatCart);
